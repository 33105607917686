import user from '@/mixins/user'
import { taxRef } from '@/firebase/db'
export default {
  mixins: [user],
  methods: {
    async getTaxHistory(parcelId) {
      let res = await taxRef.doc(parcelId).get()
      if (res.exists) {
        return res.data()
      } else {
        return null
      }
    },
    async saveTaxHistory(parcelId, data) {
      await taxRef.doc(parcelId).set({ data: data })
    },
    percentGrowth(first, last, decimals) {
      let pct = 100 * (last / first - 1)
      pct = pct.toFixed(1)
      pct = parseFloat(pct)
      if (decimals == 0) {
        return pct
      }
      return pct
    },
    getTaxSummary(data) {
      let res = {
        firstData: null,
        lastData: null,
        firstYear: null,
        lastYear: null,
        firstTax: null,
        lastTax: null,
        firstCPI: null,
        lastCPI: null,
        taxGrowth: null,
        cpiGrowth: null,
        growthRate: null,
        estimated: {
          '2025': null,
          '2030': null
        }
      }

      if (!data || typeof data !== 'object') {
        return
      }

      if (data.length < 2) {
        return
      }

      data = data.filter(item => {
        return item.cpi && item.tax
      })

      let dataSize = data.length - 1
      if (data[0].year > data[dataSize].year) {
        data = data.reverse()
      }

      res.firstData = data[0]
      res.lastData = data[dataSize]
      res.firstYear = res.firstData.year
      res.lastYear = res.lastData.year
      res.firstTax = res.firstData.tax
      res.lastTax = res.lastData.tax
      res.firstCPI = res.firstData.cpi
      res.lastCPI = res.lastData.cpi
      res.taxGrowth = this.percentGrowth(res.firstTax, res.lastTax, 0)
      res.cpiGrowth = this.percentGrowth(res.firstCPI, res.lastCPI, 0)
      res.growthRate = (res.taxGrowth / res.cpiGrowth).toFixed(1)

      let yearsTo2025 = 2025 - res.lastYear
      let yearsTo2030 = 2030 - res.lastYear
      let yearsRange = res.lastYear - res.firstYear
      let netGrowthRate = res.taxGrowth - res.cpiGrowth

      res.estimated[2025] = (
        (yearsTo2025 / yearsRange) *
        netGrowthRate
      ).toFixed(0)
      res.estimated[2030] = (
        (yearsTo2030 / yearsRange) *
        netGrowthRate
      ).toFixed(0)

      return res
    }
  }
}
