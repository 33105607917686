<template>
  <div class="ma-2">
    <div class="my-2">
      <v-chip
        :color="colors.deployment[config.deployment]"
        class="text-capitalize"
      >
        {{ config.deployment }}
      </v-chip>
    </div>
    <div>
      <v-card hover class="card-title--primary">
        <v-card-title> Data </v-card-title>
        <v-card-text>
          <json-viewer :value="config" :expand-depth="5" copyable></json-viewer>
        </v-card-text>
      </v-card>
    </div>
    <v-row class="mt-2">
      <v-col v-for="fn in functions" cols="12" sm="6" :key="fn">
        <v-card hover class="card-title--primary">
          <v-card-title>
            <code>
              {{ `${fn}()` }}
            </code>
          </v-card-title>
          <v-card-text>
            <json-viewer
              :value="config[fn]()"
              :expand-depth="5"
              copyable
            ></json-viewer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import config from '../../config'
import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)
export default {
  data: () => {
    return {
      colors: {
        deployment: {
          main: 'success',
          beta: 'info',
          dev: 'warning'
        }
      },
      functions: ['firebaseConfig', 'storageDriveBucket', 'api', 'galleryID'],
      config
    }
  }
}
</script>

<style>
</style>
