<template>
  <div>
    <div v-for="(colorGroup, index) in colorGroups" :key="index" class="mt-5">
      <h2 class="text-h4">{{ colorGroup.text }}</h2>
      <div>
        <v-row>
          <v-col
            v-for="(c, i) in colorGroup.colors"
            :key="i"
            cols="6"
            sm="2"
            lg="1"
          >
            <color-card
              class="mt-7"
              :label="c.label"
              :color="c.color"
            ></color-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import colors from '../../design/_colors.scss'
export default {
  components: {
    ColorCard: () => import('../ColorCard.vue')
  },
  mounted() {
    this.setColors()
  },
  data() {
    return {
      colorList: [],
      peepsColorFilter: ['peeps', 'feature', 'health', 'hvac'],
      colorFilter: {
        peeps: ['peeps', '']
      }
    }
  },
  computed: {
    // peepsColors() {
    //   let colors = [...this.colorList]
    //   colors = colors.filter(e => {
    //     let filter = true
    //     if (e.label.indexOf('-') != -1) {
    //       filter = !this.isInFilter(e.label.split('-')[0])
    //     }
    //     return filter
    //   })
    //   return colors
    // },

    peepsColors() {
      return this.getColors({ prefix: 'peeps', removePrefix: false })
    },

    featureColors() {
      return this.getColors({ prefix: 'feature', removePrefix: true })
    },

    healthColors() {
      return this.getColors({ prefix: 'health', removePrefix: true })
    },

    hvacColors() {
      return this.getColors({ prefix: 'hvac', removePrefix: true })
    },

    generalColors() {
      let colors = [...this.colorList]
      colors = colors.filter(c => {
        let valid = true
        const _this = this
        valid = !this.peepsColorFilter.some(e => {
          return e == c.label || _this.validateColor(c.label, e)
        })
        return valid
      })
      return colors
    },

    colorGroups() {
      let groups = [
        { text: 'General', colors: [...this.generalColors] },
        { text: 'Peeps', colors: [...this.peepsColors] },
        { text: 'Features', colors: [...this.featureColors] },
        { text: 'Health', colors: [...this.healthColors] },
        { text: 'Energy', colors: [...this.hvacColors] }
      ]
      return groups
    }
  },
  methods: {
    validateColor(colorName, filter) {
      let valid = false
      if (colorName.indexOf('-') != -1) {
        if (colorName.split('-')[0] == filter) {
          valid = true
        }
      }
      if (colorName == filter) {
        valid = true
      }
      return valid
    },
    getColors({ prefix, removePrefix }) {
      let colors = [...this.colorList]
      colors = colors.filter(color => {
        return this.validateColor(color.label, prefix)
      })
      if (removePrefix) {
        colors = colors.map(e => {
          e.label = this.removePrefix(e.label)
          return e
        })
      }
      return colors
    },
    isInFilter(text) {
      return this.peepsColorFilter.some(e => e == text)
    },
    setColors() {
      Object.keys(colors).forEach(key => {
        let data = { label: key, color: colors[key] }
        this.colorList.push(data)
      })
    },
    removePrefix(text, sep = '-') {
      let label = text.split(sep)
      label.splice(0, 1)
      return label.join(sep)
    }
  }
}
</script>

<style></style>
