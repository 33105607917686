<template>
  <v-card class="card-title--primary">
    <v-card-title>
      <slot name="title"> Send Email </slot>
    </v-card-title>
    <v-card-text class="mt-5">
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" sm="5">
            <v-text-field
              outlined
              label="To"
              :rules="rules.requiredRule"
              v-model="message.to"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="7">
            <v-text-field
              :rules="rules.requiredRule"
              outlined
              label="Subject"
              v-model="message.subject"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="4">
            <v-text-field
              outlined
              label="From Name"
              v-model="message.fromName"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="4">
            <v-text-field
              outlined
              label="From Email"
              v-model="message.fromEmail"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="d-none d-sm-block mt-3 text-h6">
            @{{ message.fromDomain }}
          </v-col>
        </v-row>

        <v-row justify="center" align="center" class="row-mt-1">
          <span>{{ formatEmail(message) }}</span>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea
              auto-grow
              :rules="rules.requiredRule"
              outlined
              label="Message (html)"
              rows="3"
              v-model="message.html"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <div class="my-3">
          <p v-html="message.html"></p>
        </div>
        <peeps-alert-animation
          :alertAttrs="alertAttrs"
          ref="peepsAlert"
          :message="alertMessage"
          :color="alertAttrs.color"
        ></peeps-alert-animation>
        <div v-if="retryInterval">
          Retrying...
          <v-btn text @click="clearRetry()">Cancel</v-btn>
        </div>
        <div v-else class="text-center">
          <v-btn
            :loading="loading"
            large
            color="primary"
            @click="sendEmail"
            outlined
            >Send
            <v-icon>mdi-email-send</v-icon>
          </v-btn>
          <span @click="cancel()">
            <cancel-button></cancel-button>
          </span>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import rules from '../../mixins/rules'
import config from '../../config'
export default {
  mixins: [rules],
  props: {
    to: {
      type: String
    },
    subject: {
      type: String
    },
    userId: {
      type: String
    }
  },
  data() {
    return {
      message: {},
      alertMessage: 'Email sent successfully!',
      alertAttrs: { outlined: true, color: 'secondary' },
      valid: true,
      loading: false,
      MAX_RETRIES: 10,
      RETRY_TIMEOUT: 5000,
      retryCount: 0,
      retryInterval: null
    }
  },
  created() {
    this.setDefaultData()
  },
  methods: {
    setDefaultData() {
      let data = {
        fromDomain: config.emailDomain,
        fromName: 'Peeps Support',
        fromEmail: 'support',
        to: this.to || '',
        subject: this.subject || ''
      }
      this.message = data
    },
    formatEmail(data) {
      return `${data.fromName}<${data.fromEmail}@${data.fromDomain}>`
    },
    showAlert(type = 'success') {
      if (type === 'success') {
        this.alertMessage = 'Email sent successfully!'
        this.alertAttrs = {}
        this.alertAttrs.color = 'success'
      }
      if (type === 'error') {
        this.alertMessage = 'Error! Could not send email'
        this.alertAttrs.color = 'peeps-light-yellow'
        this.alertAttrs.outlined = false
      }
      this.$refs.peepsAlert.onClick()
      this.$refs.peepsAlert.show()
    },
    checkRetry() {
      let res = true
      if (this.retryCount >= this.MAX_RETRIES) {
        this.clearRetry()
        res = false
      }
      return res
    },
    async retry() {
      let valid = this.checkRetry()
      if (!valid) {
        return
      }
      await this.sendEmail()
      this.retryCount++
    },
    setRetryInterval() {
      this.retryInterval = setInterval(() => {
        this.retry()
      }, this.RETRY_TIMEOUT)
    },
    clearRetry() {
      this.retryCount = 0
      clearInterval(this.retryInterval)
      this.retryInterval = null
    },
    async send(message) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post('/email/send', { message })
          .then(res => {
            let alertType = 'success'
            this.$emit('sent', message)
            if (res && !res.data.success) {
              alertType = 'error'
            }
            this.showAlert(alertType)
            if (alertType == 'success') {
              this.setDefaultData()
              this.$refs.form.resetValidation()
            }
            if (alertType == 'success') {
              resolve(res)
            } else {
              reject(res)
            }
          })
          .catch(err => {
            this.showAlert('error')
            console.log(err)
            reject(err)
          })
      })
    },
    async sendEmail() {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }
      let data = this.copyObject(this.message)
      data.from = this.formatEmail(data)
      this.loading = true
      try {
        await this.send(data)
        let eventData = { user: this.userId, ...this.message }
        this.logEvent('email', { ...eventData })
        this.clearRetry()
      } catch {
        if (!this.retryInterval) {
          this.setRetryInterval()
        }
        this.showAlert('error')
      }
      this.loading = false
    },
    cancel() {
      this.setDefaultData()
      this.$emit('cancel')
      this.$refs.form.resetValidation()
    }
  }
}
</script>

<style></style>
