<template>
  <v-main>
    <v-container fluid>
      <h1 class="display-1">Sandbox</h1>
      <v-tabs v-model="tab" grow center-active>
        <v-tab @click="changeTabURL(i)" v-for="(t, i) in tabs" :key="i">
          {{ t }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="mt-3">
        <v-tab-item>
          <tax-cpi-rate-growth></tax-cpi-rate-growth>
        </v-tab-item>
        <v-tab-item>
          <send-email></send-email>
        </v-tab-item>
        <v-tab-item>
          <default-colors></default-colors>
        </v-tab-item>
        <v-tab-item>
          <ConfigFile />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-main>
</template>

<script>
import TaxCpiRateGrowth from '@/components/Admin/TaxCpiRateGrowth'
import SendEmail from '../../../components/Admin/SendEmail.vue'
import DefaultColors from '../../../components/Admin/DefaultColors'
import ConfigFile from '../../../components/Admin/ConfigFile'
export default {
  components: {
    TaxCpiRateGrowth,
    SendEmail,
    DefaultColors,
    ConfigFile
  },
  data() {
    return {
      tab: null,
      tabs: ['Tax CPI Rate Growth', 'Send Email', 'Colors', 'Config File']
    }
  },
  mounted() {
    this.setTabFromURL()
  },
  methods: {
    setTabFromURL() {
      let tab = this.$route.query.tab
      tab = Number(tab)
      if (tab) {
        this.changeTabURL(tab)
        this.tab = tab
      }
    },
    changeTabURL(tab) {
      let url = `${this.$route.path}?tab=${tab}`
      if (this.$route.fullPath != url) {
        this.$router.push(url)
      }
    }
  }
}
</script>

<style></style>
